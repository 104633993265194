import React, { PureComponent, useState, useEffect } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, NavLink, useParams, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, Col } from 'reactstrap';
import validate from 'lib/validations/login';
import { Input } from 'antd';

import { Formik, withFormik, Field, Form } from 'formik';
import FormikMaterialTextField from 'shared/components/form/FormikMaterialTextField';
import FormikCheckBox from 'shared/components/form/FormikCheckBox';
import * as handlers from './../handlers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import config from 'config';
import CheckCircleIcon from '@material-ui/icons/ErrorOutline';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './custom.css';
import { getDecryptedUrl } from 'lib/utils';

const LoginForm = (props) => {
  const loc = getDecryptedUrl(window.location.href);
  const { indentDealsUrl } = config;
  const location = useLocation();
  const history = useHistory()
  let teamEmail = '';
  if (location.search !== '') {
    const teamLoginURL = location.search.split('?u=');
    if (teamLoginURL[1] !== null) {
      teamEmail = atob(teamLoginURL[1]);
    }
  }

  const [lengthCount, setLengthCount] = useState(0);
  const [showPhoneEmail, setShowPhoneEmail] = useState('email');
  const [showPhone, setShowPhone] = useState(false);
  const [showContryCode, setShowContryCode] = useState(91);
  const [showEmail, setShowEmail] = useState(false);
  const [countryPhone, setCountryPhone] = useState('');

  // useEffect(() => {
  //   const pattern = /^-?\d+$/;
  //   if (lengthCount.length >= 8) {
  //     if (pattern.test(lengthCount) && lengthCount != 0) {
  //       setShowPhone(true);
  //       setShowEmail(false);
  //     } else {
  //       setShowEmail(true);
  //       setShowPhone(false);
  //     }
  //   } else {
  //     setShowEmail(true);
  //     setShowPhone(false);
  //   }
  // }, [lengthCount])

  useEffect(() => {
    const previousUrl = history.location.state && history.location.state.from.pathname + history.location.state.from.search;
    localStorage.setItem("PreviousUrl", previousUrl)
  }, [history]);

  const whatsAppButtonStyle = {
    backgroundColor: '#25D366',
    padding: '10px 40px',
    border: 'none',
    borderRadius: '6px',
    fontSize: ' var(--fs-base__two)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }

  const separatorStyle = {
    position: 'absolute',
    top: '-12px',
    background: '#fff',
    left: '44%',
    height: '25px',
    width: '36px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#707070',
  }

  // const validatePhoneNumber = (value, country) => {
  //   const onlyNumbers = value.replace(/\D/g, '');
  //   return onlyNumbers.length === 10;
  // };

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('authChannel');
    const handlePageReload = () => {
      // Refresh the current tab
      window.location.reload();
    };

    broadcastChannel.onmessage = (event) => {
      if (event.data === 'loggedIn') {
        handlePageReload();
      }
    };

    return () => {
      broadcastChannel.close();
    };
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="pb-15">
        <CardBody>
          <Formik
            initialValues={{
              user_name: teamEmail || '',
              agree: true,
              domain_url: window.location.origin,
              country_code: 0,
            }}
            validate={validate}
            onSubmit={handlers.handleRequestOTP}>
            {(formikBag) => {
              const { initialValues, values } = formikBag;
              console.log('check values', initialValues, values)
              return (
                <Form className="material-form">
                  <div>
                    <div className="login-signup__page">
                      <br />
                      <div className="d-flex align-items-baseline login-card mb-2">
                        {showPhoneEmail === 'email' ? (
                        <Field
                          style={{ width: formikBag.isSubmitting ? 'calc(100% - 162px)' : 'calc(100% - 138px)' }}
                          className="mr-3"
                          name="user_name"
                          variant={'outlined'}
                          size="small"
                          // className="bfc-login-page"
                          component={FormikMaterialTextField}
                          placeholder="Enter Email"
                          // label="Enter Mobile Number/ Email Address"
                          // setLengthCount={setLengthCount}
                          setShowDisabled={props.setShowDisabled}
                          setShowEmail={setShowEmail}
                          islogin={true}
                        />
                        ) : (
                          <>
                            <PhoneInput
                              country={'in'}
                              // value={lengthCount === 0 ? countryPhone : lengthCount}
                              // value={lengthCount}
                              preferredCountries={['in']}
                              // placeholder="Enter Mobile Number"
                              enableSearch={true}
                              onChange={(event, name) => {
                                  setCountryPhone(event);
                                  const originalString = event || '';
                                  const dialCodeLength = name.dialCode;
                                  const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                                  if (originalString > 0) {
                                    formikBag.setFieldValue('user_name', stringWithoutFirstThree || '');
                                    formikBag.setFieldValue('country_code', parseInt(name.dialCode) || 0);
                                    setLengthCount(stringWithoutFirstThree);
                                    setShowContryCode(parseInt(name.dialCode))
                                  } else {
                                    formikBag.setFieldValue('user_name', '');
                                    formikBag.setFieldValue('country_code', 0);
                                    setShowContryCode(91)
                                    setLengthCount(0);
                                  }
                                  if (name.dialCode != "91") {
                                    props.setShowDisabled(true);
                                  } else {
                                    props.setShowDisabled(false);
                                  }
                                }
                              }
                              prefix={''}
                              autoFormat={false}
                              // isValid={validatePhoneNumber}
                              // countryCodeEditable={false}
                              inputProps={{
                                name: 'mobile_number',
                                required: true,
                                placeholder: "Enter Mobile Number",
                                autoFocus: true,
                                className: ((lengthCount.length > 10 || lengthCount.length < 10) && formikBag.values.user_name != '' && showPhoneEmail === 'phone no') ? 'custom-phone-input error-msg__bordered' : 'custom-phone-input',
                                'data-country-code': `+${''}`,
                              }}
                            />
                            <span className="country-code position-absolute">{'+' + showContryCode}</span>
                          </>
                        )}
                        <div className="bfc-center">
                          <button
                            disabled={(lengthCount.length > 10 && !showEmail) || formikBag.isSubmitting || props.showDisabled}
                            type="submit"
                            color="primary"
                            size="sm"
                            style={{ ...whatsAppButtonStyle, backgroundColor: "var(--unnamed-color-1b62ab)", opacity: props.showDisabled && '0.6', cursor: props.showDisabled && 'not-allowed' }}>
                            {formikBag.isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            )}
                            Verify
                          </button>
                        </div>
                      </div>
                      {((lengthCount.length > 10 || lengthCount.length < 10) && formikBag.values.user_name != '' && showPhoneEmail === 'phone no') ? (
                        <span className="error-msg">Phone number must be 10 digits.</span>
                      ) : (showEmail && formikBag.values.user_name != '' && showPhoneEmail === 'email') && (
                        <span className="error-msg">Email address cannot start with a number.</span>
                      )}

                      <p>{showPhoneEmail === 'phone no' ? <span>Login using <span className="text-blue cursor-pointer" onClick={() => {setShowPhoneEmail('email'); formikBag.setFieldValue('user_name', '')}}>Email</span></span>
                        : <span>Login using <span className="text-blue cursor-pointer" onClick={() => {
                          setShowPhoneEmail('phone no'); formikBag.setFieldValue('user_name', '')}}>Phone number</span></span> }
                      </p>
                      <div className='position-relative'>
                        <hr />
                        <span style={separatorStyle}>OR</span>
                      </div>
                      <div>
                        <button
                          size="sm"
                          className='mr-3 mb-3'
                          style={whatsAppButtonStyle}
                          onClick={() => props?.initiateLogin()}
                        >
                          <WhatsAppIcon size={24} className="mr-2" />
                          Login With Whatsapp
                        </button>
                      </div>
                    </div>

                    {/* <p className="bfc-otpVerification">
                      A six digit OTP will be sent via SMS/ <br />
                      Email for verification
                      <br />
                      <br />
                    </p> */}
                  </div>
                  {
                    <div className="form__form-group">
                      <div className="w-100 ml-0">
                        <Field
                          name="agree"
                          component={FormikCheckBox}
                          label={
                            <div className="bfc-login-page">
                              <span>
                                Agree with{' '}
                                {!loc.includes(indentDealsUrl) ? (
                                  <Link className="bfc-underline" to="/terms-and-conditions.html" target="_blank">
                                    Terms and Conditions
                                  </Link>
                                ) : (
                                  <Link className="bfc-underline" to="/terms-and-conditions-supply-support.html" target="_blank">
                                    Terms and Conditions
                                  </Link>
                                )}
                              </span>
                              {/* <br /> */}
                              {!loc.includes(indentDealsUrl) && (
                                <span> & </span>
                              )}
                              {!loc.includes(indentDealsUrl) && (
                                <span>
                                  <Link className="bfc-underline" to="/privacy-notice.html" target="_blank">
                                    Privacy Notice
                                  </Link>
                                </span>
                              )}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  }
                  {/* <div className="bfc-center">
                    <Button disabled={formikBag.isSubmitting} type="submit" color="primary" size="sm">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      Request OTP
                    </Button>
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoginForm;
