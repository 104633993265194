import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };

    return utils.getQs(qsParams);
  },
  fetchDetails(params) {
    // const qsParams = {
    //   ...params,
    //   entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
    //   user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
    //   company_affiliate_enum: 1,
    // };

    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum', 0),
          company_affiliate_enum: 1,
        };
      } else {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
          company_affiliate_enum: 1,
        };
      }
    } else {
      qsParams = {
        ...params,
        entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
        company_affiliate_enum: 1,
      };
    }
    delete qsParams['person_id'];
    return utils.getQs(qsParams);
  },
  create(params = {}) {
    const sameAsRegisterAddress = params['same_as_registered_address'] || false;
    let personAddressId = 0;
    if (sameAsRegisterAddress === true) {
      personAddressId = _.get(window, 'user.profile.person_adress.person_address_id') || 0;
    }

    let bodyParams = {
      ...params,
      person_kyc_id: 0,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      state_id: parseInt(params['state_id']),
      city_id: parseInt(params['city_id']) || 0,
      // city_code: params['city_code'] || '',
      person_address_id: personAddressId,
    };

    //Remove Tax Identity Parameters
    delete bodyParams['person_tax_detail_id'];
    delete bodyParams['pan_id'];
    delete bodyParams['name_as_per_pan'];
    delete bodyParams['dont_have_pan_flag'];
    delete bodyParams['form_60_declaration_flag'];

    //Remove Bank Parameters
    delete bodyParams['bank_account_id'];
    delete bodyParams['account_name'];
    delete bodyParams['account_number'];
    delete bodyParams['branch'];
    delete bodyParams['ifsc_code'];
    delete bodyParams['bank_address'];

    return bodyParams;
  },

  createStatus(params = {}) {
    const sameAsRegisterAddress = params['same_as_registered_address'] || false;
    let personAddressId = 0;
    if (sameAsRegisterAddress === true) {
      personAddressId = _.get(window, 'user.profile.person_adress.person_address_id') || 0;
    }
    //  const entity_id1: params['entity_id'];

    let bodyParams = {
      ...params,
      entity_id: parseInt(params['entity_id']),
      user_type_enum: params['user_type_enum'],
      company_affiliate_enum: params['company_affiliate_enum'],
      control_tower_person_id: _.get(window, 'user.userid', 0),
      kyc_status_enum: params['kyc_status_enum'],
      note_text: params['notes'],
    };

    return bodyParams;
  },

  update(id, params) {
    const sameAsRegisterAddress = params['same_as_registered_address'] || false;
    let personAddressId = 0;
    if (sameAsRegisterAddress === true) {
      personAddressId = _.get(window, 'user.profile.person_adress.person_address_id') || 0;
    }

    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      state_id: parseInt(params['state_id']),
      city_id: parseInt(params['city_id']) || 0,
      // city_code: params['city_code'] || '',
      person_address_id: personAddressId,
    };

    //Remove Tax Identity Parameters
    delete bodyParams['person_tax_detail_id'];
    delete bodyParams['pan_id'];
    delete bodyParams['name_as_per_pan'];
    delete bodyParams['dont_have_pan_flag'];
    delete bodyParams['form_60_declaration_flag'];

    //Remove Bank Parameters
    delete bodyParams['bank_account_id'];
    delete bodyParams['account_name'];
    delete bodyParams['account_number'];
    delete bodyParams['branch'];
    delete bodyParams['ifsc_code'];
    delete bodyParams['bank_address'];

    return bodyParams;
  },

  //Tax identity

  fetchTaxDetails(params) {
    // const qsParams = {
    //   ...params,
    //   entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
    //   user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0), //2
    //   company_affiliate_enum: 1,
    // };

    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum') || 0,
          company_affiliate_enum: 1,
        };
      } else {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
          company_affiliate_enum: 1,
        };
      }
    } else {
      qsParams = {
        ...params,
        entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
        company_affiliate_enum: 1,
      };
    }

    return utils.getQs(qsParams);
  },
  createTax(params = {}) {
    let bodyParams = {
      person_tax_detail_id: params['person_tax_detail_id'],
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      pan_id: params['pan_id'],
      name_as_per_pan: params['name_as_per_pan'],
      dont_have_pan_flag: params['dont_have_pan_flag'],
      form_60_declaration_flag: params['form_60_declaration_flag'],
      operation_Enum: 1,
    };
    return bodyParams;
  },

  updateTax(id, params) {
    let bodyParams = {
      person_tax_detail_id: params['person_tax_detail_id'],
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      pan_id: params['pan_id'],
      name_as_per_pan: params['name_as_per_pan'],
      dont_have_pan_flag: params['dont_have_pan_flag'],
      form_60_declaration_flag: params['form_60_declaration_flag'],
      operation_Enum: 3,
    };
    return bodyParams;
  },

  //Bank
  fetchBankDetails(params) {
    // const qsParams = {
    //   ...params,
    //   entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
    //   user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0), //2
    // };

    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum') || 0,
          company_affiliate_enum: 1,
        };
      } else {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
          company_affiliate_enum: 1,
        };
      }
    } else {
      qsParams = {
        ...params,
        entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
        company_affiliate_enum: 1,
      };
    }
    return utils.getQs(qsParams);
  },

  createBank(params = {}) {
    let bodyParams = {
      entity_id: params['entity_id'] || _.get(window, 'user.userid', 0),
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0), //2
      company_affiliate_enum: 1,
      bank_account_details_request_model: params['bank_account_details_request_model'],
    };
    return bodyParams;
  },

  updateBank(id, params) {
    let bodyParams = {
      entity_id: params['entity_id'] || _.get(window, 'user.userid', 0),
      user_type_enum: params['user_type_enum']
        ? params['user_type_enum']
        : _.get(window, 'user.profile.person.user_type_enum', 0), //2
      company_affiliate_enum: 1,
      bank_account_details_request_model: params['bank_account_details_request_model'],
    };
    return bodyParams;
  },

  eKycDocumentVerification(params) {
    let bodyParams = {};
    if (params['eKyc'] == 'Document') {
      let kycVerificationTypeEnum = 0;
      if (params['identity_type_enum'] == 2) {
        kycVerificationTypeEnum = 3; //DrivingLicence
      } else if (params['identity_type_enum'] == 3) {
        kycVerificationTypeEnum = 4; //VoterID
      }
      bodyParams = {
        verification_type_enum: kycVerificationTypeEnum,
        document_number: params['identity_doc_number'],
        document_name: params['name_as_per_identity'],
        company_affiliate_enum: 0,
        expiry_date: null,
        entity_id: _.get(window, 'user.userid') || 0,
        dob: params['dob'] || '',
      };
    }
    if (params['eKyc'] == 'Pan') {
      bodyParams = {
        document_number: params['pan_id'],
        verification_type_enum: 1,
        document_name: params['name_as_per_pan'],
        company_affiliate_enum: 0,
        expiry_date: null,
        entity_id: _.get(window, 'user.userid') || 0,
      };
    }
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  fetchEKycStatusList(params = {}) {
    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
      };
    }
    delete qsParams['person_id'];

    return utils.getQs(qsParams);
  },
  verifyIFSC(params) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  verifyBank(params = {}) {
    const qsParams = {
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      ...params,
    };
    return utils.getQs(qsParams);
  },
  verifyIfscZoopApi(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  completeBank(params) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  deleteBank(params = {}) {
    const qsParams = {
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let affiliateData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(affiliateData, 'company_affiliate_id');
  },
  fetchDetails(resData) {
    return {
      address_line_1: _.get(resData, 'address_line_1') || '',
      address_line_2: _.get(resData, 'address_line_2') || '',
      address_line_3: _.get(resData, 'address_line_3') || '',
      identity_doc_number: _.get(resData, 'identity_doc_number') || '',
      identity_type_enum: _.get(resData, 'identity_type_enum') || '',
      identity_type_enum_code: _.get(resData, 'identity_type_enum_code') || '',
      name_as_per_identity: _.get(resData, 'name_as_per_identity') || '',
      person_id: _.get(resData, 'person_id') || '',
      person_kyc_id: _.get(resData, 'person_kyc_id') || '',
      pin_code: _.get(resData, 'pin_code') || '',
      same_as_registered_address: _.get(resData, 'same_as_registered_address') || '',
      state_code: _.get(resData, 'state_code') || '',
      state_id: _.get(resData, 'state_id') || '',
      district_code: _.get(resData, 'district_code') || '',
      taluka_code: _.get(resData, 'taluka_code') || '',
      village_code: _.get(resData, 'village_code') || '',
      village_id: _.get(resData, 'village_id') || '',
      city_code: _.get(resData, 'city_code') || '',
      city_id: _.get(resData, 'city_id') || '',
      location_code: _.get(resData, 'location_code') || '',
      company_address_id: _.get(resData, 'company_address_id') || 0,
      location_tuple: _.get(resData, 'location_tuple') || '',
      dob: _.get(resData, 'dob') || '',
      license_number: _.get(resData, 'license_number') || '',
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },

  //Tax
  fetchTaxList(resData) {
    let affiliateData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchTaxDetails(details);
    });
    return _.keyBy(resData, 'person_tax_detail_id');
  },
  fetchTaxDetails(resData) {
    let response = {
      ...resData,
      person_tax_detail_id: _.get(resData, 'person_tax_detail_id'),
    };
    return response;
  },

  //Bank
  fetchBankList(resData) {
    let bankData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchBankDetails(details);
    });
    return _.keyBy(bankData, 'person_bank_account_id');
  },
  fetchBankDetails(resData) {
    let response = {
      ...resData,
      person_bank_account_id: _.get(resData, 'person_bank_account_id'),
      bank_account_id: _.get(resData, 'person_bank_account_id'),
    };
    return response;
  },
  eKycVerification(resData) {
    return resData;
  },
  fetchEKycStatusList(resData) {
    let eKycStatusData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchEKycStatusDetails(details);
    });
    return _.keyBy(eKycStatusData, 'enum_value');
  },
  fetchEKycStatusDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'enum_value'),
    };
    return res;
  },
  verifyIFSC(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  verifyBank(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  verifyIfscZoopApi(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  completeBank(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  deleteBank(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
};
