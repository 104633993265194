import React, { useEffect, useState, useRef } from 'react';
import { Table, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled, LinkOutlined } from '@ant-design/icons';

import AntPagination from 'common/AntTablePagination';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import AmountField from 'common/TableComponents/amountField';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import ClosingDateField from 'common/TableComponents/closingDateField';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import AntTableStatus from 'common/AntTableStatus';
import AntTableActions from 'common/TableActions';
import { actions as rentalActions } from 'redux/equipmentRental';
import OverviewHeader from 'common/OverviewHeader';
import FilterUtils from 'lib/filterUtils';
import useQuery from 'lib/hooks/useQuery';
import utils, {  getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import { paymentService } from 'services';
// import LogoLoader from 'common/LogoLoader';
import notifications from 'lib/notifications';
import MUIButton from 'common/MUIComponents/Button';
import { actions as dealInvoicesActions } from 'redux/dealInvoices';

const EquipmentPayments = () => {
  const dispatch = useDispatch();
  const {
    commodity_deal_master_id,
    equipment_id,
    registration_no,
    equipment_category_id,
    equipment_type_id,
    equipment_make_id,
    equipment_address,
    start_date,
    end_date,
    order_date,
    equipment_owner,
    hired_by,
    payment_term_id,
    eq_status,
    page = 1,
    deal_id,
    transport_deal_master_id,
    transport_req_offer_address_id,
    action,
    back,
  } = useQuery();

  const actionText = action === 'link' ? 'Link Equipment' : 'Delink Equipment';
  const heading = deal_id ? `Deal(#${deal_id}) > ${actionText} > Equipment Rental Transactions` : 'Equipment Rental Transactions';

  const history = useHistory();
  const tableRef = useRef();
  const csvLink = useRef();
  const rentalState = useSelector((state) => state.rental);
  const equipmentPaymentData = Object.values(rentalState.entities);
  const reportData = _.get(rentalState, 'reportData') || [];

  const busy = _.get(rentalState, 'busy') || false;
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);

  const loc = getDecryptedUrl(window.location.href)

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(deal_id ? { ...params, deal_id } : params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1${deal_id ? `&deal_id=${deal_id}` : ''}`);
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      commodity_deal_master_id,
      equipment_id,
      registration_no,
      equipment_category_id,
      equipment_type_id,
      equipment_make_id,
      equipment_address,
      start_date,
      end_date,
      order_date,
      equipment_owner,
      hired_by,
      payment_term_id,
      eq_status,
      transport_deal_master_id
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
      ...(action === 'delink' && { fetch_linked_rental_transactions: deal_id }),
      ...(action === 'link' && { transport_req_offer_address_id: transport_req_offer_address_id }),
    };
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(rentalActions.fetchAllRentalTransactions(params));
  }, [
    page,
    rowsPerPage,
    commodity_deal_master_id,
    equipment_id,
    registration_no,
    equipment_category_id,
    equipment_type_id,
    equipment_make_id,
    equipment_address,
    start_date,
    end_date,
    order_date,
    equipment_owner,
    hired_by,
    payment_term_id,
    eq_status,
    transport_deal_master_id,
    isReRenderRequired,
    action
  ]);

  const handleShowLinkedDeals = async (row, deal_delivery_enum) => {
    const linkedDealDeliveryParams = {
      deal_delivery_enum,
      deal_delivery_id: row?.commodity_deal_master_id
    };
    let linkedDealDeliveries = await dispatch(dealInvoicesActions.getLinkedDealDelivery(linkedDealDeliveryParams));
    linkedDealDeliveries = linkedDealDeliveries?.map(item => item?.linked_deal_deliveries);
    const commodityMasterId = row.commodity_deal_master_id.toString();
    const toDealId = linkedDealDeliveries.join(',');
    const dealIdsData = commodityMasterId.concat(',', toDealId);
    let params = {
      commodity_deal_master_id: dealIdsData,
    };
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
  }

  const EQUIPMENT_TRANSACTIONS_TABLE_HEADERS = [
    {
      title: 'Rental Id',
      dataIndex: 'commodity_deal_master_id',
      width: '130px',
      align: 'center',
      fixed: true,
      ellipsis: true,
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      render: (text, row) => (
        <div className="bfc-table-list-components d-flex justify-content-center align-items-center">
          {/* {row.payment_master_id ? <Link to={row.detailsPageLink}>{row.payment_master_id}</Link> : 'N/A'} */}
          {row.commodity_deal_master_id}
          {row.commodity_deal_master_id && row.to_deal_ids ?
            <div style={{ paddingLeft: '5px' }}>
              <LinkOutlined
                onClick={() => handleShowLinkedDeals(row, 1)}
                rotate={45}
                style={{ fontSize: 'var(--fs-base__one)', color: 'var(--unnamed-color-1b62ab)' }}
              />
            </div>
            : ''
          }
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'commodity_deal_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_deal_master_id}
          name={'Equipment ID'}
          isDealDealiveryMultiselectFilter
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_deal_master_id'),
      filteredValue: commodity_deal_master_id !== undefined ? commodity_deal_master_id : null,
    },
    {
      title: 'Equipment Id',
      dataIndex: 'equipment_id',
      width: '150px',
      align: 'center',
      ellipsis: true,
      sorter: (a, b) => a.equipment_id - b.equipment_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {/* {row.payment_master_id ? <Link to={row.detailsPageLink}>{row.payment_master_id}</Link> : 'N/A'} */}
          {row.equipment_id}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'equipment_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_id}
          name={'Equipment ID'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'equipment_id'),
      filteredValue: equipment_id !== undefined ? equipment_id : null,
    },
    {
      title: 'Registration No',
      dataIndex: 'registration_no',
      width: '160px',
      align: 'center',
      ellipsis: true,
      sorter: (a, b) => a.registration_no - b.registration_no,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.registration_no}
          {/* {row?.registration_no.replace(/\n/g, ' ')} */}
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'registration_no'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={registration_no}
          name={'Registration No'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'registration_no'),
      filteredValue: registration_no !== undefined ? registration_no : null,
    },
    {
      title: 'Category',
      dataIndex: 'equipment_category_code',
      key: 'equipment_category_code',
      width: '130px',
      align: 'left',
      sorter: (a, b) => a.equipment_category_id - b.equipment_category_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_category_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_category_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_category_id}
          name={'Category'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_category_id'),
      filteredValue: equipment_category_id !== undefined ? equipment_category_id : null,
    },
    {
      title: 'Type',
      dataIndex: 'equipment_type_code',
      key: 'equipment_type_code',
      width: '130px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) =>
        (a.equipment_type_code &&
          a.equipment_type_code.localeCompare(b.equipment_type_code && b.equipment_type_code)) ||
        (a.equipment_type_code && a.equipment_type_code.localeCompare(b.equipment_type_code && b.equipment_type_code)),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_type_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_type_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_type_id}
          name={'Type'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_type_id'),
      filteredValue: equipment_type_id !== undefined ? equipment_type_id : null,
    },
    {
      title: 'Maker & Variant',
      dataIndex: 'equipment_variant_code',
      key: 'equipment_variant_code',
      width: '200px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) =>
        (a.equipment_make_code &&
          a.equipment_make_code.localeCompare(b.equipment_make_code && b.equipment_make_code)) ||
        (a.equipment_make_code && a.equipment_make_code.localeCompare(b.equipment_make_code && b.equipment_make_code)),
      render: (text, row) => <div className="bfc-table-list-components">{row.equipment_make_code}{'/'}{row.equipment_variant_code}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_make_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_make_id}
          name={'Maker'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_make_id'),
      filteredValue: equipment_make_id !== undefined ? equipment_make_id : null,
    },
    {
      title: 'Location',
      dataIndex: 'equipment_address',
      key: 'equipment_address',
      width: '200px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => <div className="bfc-table-list-components">{row.equipment_address}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_address'}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_address}
          name={'Location'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_address'),
      filteredValue: equipment_address !== undefined ? equipment_address : null,
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '150px',
      ellipsis: true,
      // align: 'left',
      sorter: (a, b) => a.order_date && a.order_date.localeCompare(b.order_date && b.order_date),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.order_date ? dayjs(row.order_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'order_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={order_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'order_date'),
      defaultFilteredValue: order_date !== undefined ? order_date : null,
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '150px',
      ellipsis: true,
      // align: 'left',
      sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date && b.start_date),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.start_date ? dayjs(row.start_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'start_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={start_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'start_date'),
      defaultFilteredValue: start_date !== undefined ? start_date : null,
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '150px',
      ellipsis: true,
      // align: 'left',
      sorter: (a, b) => a.end_date && a.end_date.localeCompare(b.end_date && b.end_date),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.end_date ? dayjs(row.end_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'end_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={end_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'end_date'),
      defaultFilteredValue: end_date !== undefined ? end_date : null,
    },
    {
      title: 'Owner',
      dataIndex: 'equipment_owner',
      key: 'equipment_owner',
      width: '200px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.equipment_owner && a.equipment_owner.localeCompare(b.equipment_owner && b.equipment_owner),
      render: (text, row) => <div className="bfc-table-list-components">{row.equipment_owner}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'equipment_owner'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_owner}
          name={'Equipment Owner'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'equipment_owner'),
      filteredValue: equipment_owner !== undefined ? equipment_owner : null,
    },
    {
      title: 'Hired By',
      dataIndex: 'buyer_company_code ? buyer_company_code : buyer_person_code',
      key: 'buyer_company_code',
      width: '200px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.buyer_company_code && a.buyer_company_code.localeCompare(b.buyer_company_code && b.buyer_company_code),
      render: (text, row) => <div className="bfc-table-list-components">{row.buyer_company_code ? row.buyer_company_code : row.buyer_person_code}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'hired_by'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={hired_by}
          name={'Equipment Owner'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'hired_by'),
      filteredValue: hired_by !== undefined ? hired_by : null,
    },
    {
      title: 'Rate (Ccy & UOM)',
      dataIndex: 'trade_price',
      key: 'trade_price',
      width: '120px',
      ellipsis: true,
      render: (text, row) => <div className="bfc-table-list-components text-right">{'₹' + ' ' + utils.formatQtyPricePerUOM(row.trade_price) + '/' + row.price_uom_code}</div>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '120px',
      ellipsis: true,
      render: (text, row) => <AmountField expected_price={row.amount} type={'payment'} />,
    },
    {
      title: 'Payment Terms',
      dataIndex: 'payment_term_code',
      key: 'payment_term_code',
      width: '200px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => <div className="bfc-table-list-components">{row.payment_term_code}</div>,
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'payment_term_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_term_id}
          name={'Payment Terms'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'payment_term_id'),
      filteredValue: payment_term_id !== undefined ? payment_term_id : null,
    },
    {
      title: 'ASC',
      dataIndex: 'transport_deal_master_id',
      width: '110px',
      align: 'center',
      ellipsis: true,
      sorter: (a, b) => a.transport_deal_master_id - b.transport_deal_master_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          <Link to={row.deliveryContractPageLink}>{row.transport_deal_master_id}</Link>
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'transport_deal_master_id'}
          data={props}
          isDealDealiveryMultiselectFilter
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={transport_deal_master_id}
          name={'Equipment ID'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'transport_deal_master_id'),
      filteredValue: transport_deal_master_id !== undefined ? transport_deal_master_id : null,
    },
    {
      title: 'Status',
      dataIndex: 'eq_status_code',
      key: 'eq_status_code',
      width: '150px',
      // align: 'left',
      sorter: (a, b) => a.eq_status_code && a.eq_status_code.localeCompare(b.eq_status_code && b.eq_status_code),
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.eq_status_code}</div>,
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'eq_status'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={eq_status}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'eq_status'),
      defaultFilteredValue: eq_status !== undefined ? eq_status : null,
    },

  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  let tableData = equipmentPaymentData;
  const totalRecords = tableData?.[0]?.total_count || 0;
  const encodeBackUrl = utils.encodeBackUrl();
  tableData = tableData.map((d) => {
    return {
      ...d,
      detailsPageLink: getUrlWithEncryptedQueryParams(`/payment/${d.commodity_deal_master_id}/overview?back=${encodeBackUrl}`),
      deliveryContractPageLink: getUrlWithEncryptedQueryParams(`/controltower/delivery-contract?page=1&transport_deal_id=${d.transport_deal_master_id}&back=${encodeBackUrl}`),
    };
  });

  tableData = _.orderBy(tableData, ['commodity_deal_master_id'], ['desc']);

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    return () => {
      setSelectedRowKeys([])
    }
  }, []);

  const onSelectChange = (selectedRowKeys, row) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(row);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    ...(action === 'link' && {
      getCheckboxProps: (record) => ({
        disabled: record.eq_status != 3,
      }),
    }),
  };

  const getExportReport = async () => {
    const filterParams = {
      filters: {
        ...filters,
      },
      page_size: 10000,
      offset: 0,
      isCsv: true
    };
    notifications.show({ type: 'info', message: 'Downloading Started...' })
    const success = await dispatch(rentalActions.fetchAllRentalTransactions(filterParams));
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully'})
    }
  };

  console.log(selectedRowKeys,"selectedRowKeys")

  return (
    <dev>
      <OverviewHeader
        backLink={back}
        heading={heading}
        rightPanContent={
          selectedRowKeys?.length > 0 ? (
            <MUIButton
              onClick={async () => {
                const success = await dispatch(
                  rentalActions.updateTransportDealToEquipmentDeal(deal_id, selectedRowKeys, action === 'delink')
                );
                if (success) {
                  notifications.show({
                    type: 'success',
                    message: `Equipments ${action === 'delink' ? 'delinked' : 'linked'} successfully`
                  });
                  setSelectedRowKeys([]);
                  setSelectedRows([]);
                  setIsReRenderRequired(!isReRenderRequired);
                }
              }}
            >
              {action === 'delink' ? 'Delink Equipment' : 'Link Equipment'}
            </MUIButton>
          ) : ''
        }
      />
      <div className="invoice-table bfc-body">
        {/* {busy && (
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        )} */}
        <div>
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? EQUIPMENT_TRANSACTIONS_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : EQUIPMENT_TRANSACTIONS_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 510 }}
              className="ant-table"
              size="small"
              rowKey={(record) => record.commodity_deal_master_id}
              rowSelection={deal_id ? rowSelection : false}
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              clearFilters={clearFilters}
              setScrollToFirstRow={setScrollToFirstRow}
              reportData={reportData}
              getExportReport={getExportReport}
              csvLink={csvLink}
              type={'Equipment Rental Transactions'}
            />
          </div>
        </div>
      </div>
    </dev>
  );
};
export default EquipmentPayments;
