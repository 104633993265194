import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Skeleton, Popover, Popconfirm } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';

import OverviewHeader from 'common/OverviewHeader';
import AntPagination from 'common/AntTablePagination';
import { actions as rentalActions } from 'redux/equipmentRental';
import useQuery from 'lib/hooks/useQuery';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterUtils from 'lib/filterUtils';
import LoadingIcon from 'mdi-react/LoadingIcon';
import MUIButton from 'common/MUIComponents/Button';
import AddLinkIcon from 'mdi-react/LinkAddIcon';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import { actions as generateEquipmentRentalCSVReportActions } from 'redux/generateReportCommon';
import utils, { getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import notifications from 'lib/notifications';
import MapTelemetryIdPopupForm from 'common/MapTeleIdPopup';
import { DeLinkTelemetryId } from 'common/MapTeleIdPopup/handlers';

const EquipmentRental = () => {
  const dispatch = useDispatch();
  const { page = 1 } = useQuery();
  const history = useHistory();
  const csvLink = useRef();
  const tableRef = useRef();

  let {
    equipment_master_id,
    equipment_type_id,
    equipment_make_id,
    creation_year,
    registration_no,
    model_no,
    equipment_variant_id,
    verification_status_enum,
    equipment_category_id,
    equipment_model_id,
    serial_number,
    entity_code,
    equipment_address,
    equipment_description,
    telemetry_device_id,
  } = useQuery();
  const loc = getDecryptedUrl(window.location.href);
  const isMapTracker = loc.includes('equipmentRental/mapTrackers')
  const rentalState = useSelector((state) => state.rental);
  const equipmentData = Object.values(rentalState.entities);
  const busy = _.get(rentalState, 'busy') || false;
  const [showMapTeleId, setShowMapTeleId] = useState(false)
  const [singleRowData, setSingleRowData] = useState({})
  const [rerender, setRerender] = useState(false);

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const user = useSelector((state) => state.user);
  const isCTCommercial = user?.profile?.person_role?.some(item => item?.role_code === 'CT Commercial');
  const isCTSuperuser = user?.profile?.person_role?.some(item => item?.role_code === 'CT Superuser');
  const isCTFacilitator = user?.profile?.person_role?.some(item => item?.role_code === 'CT Facilitator');
  const isCTOperations = user?.profile?.person_role?.some(item => item?.role_code === 'CT Operations');
  const isAdmin = user?.profile?.person_role?.some(item => item?.role_code === 'Admin');
  const isOperations = user?.profile?.person_role?.some(item => item?.role_code === 'Operations');
  const isFacilitator = user?.profile?.person_role?.some(item => item?.role_code === 'Facilitator');
  const isCtUser = user?.profile?.person?.is_control_tower_user;

  const canViewMapTracker = isCTCommercial || isCTSuperuser || isCTFacilitator || isAdmin || isOperations || isFacilitator || isCTOperations
  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const handleDelinkId = (telemteryId, equipmentMasterId) => {
    const handleDeLinkTelemetryId = async () => {
      try {
        const bodyParams = {
          equipment_master_id: equipmentMasterId,
          telemetry_id: telemteryId
        };
        const res = await DeLinkTelemetryId(bodyParams);
        setRerender(!rerender);
      } catch (error) {
        console.log(error)
      }
    };
    if (telemteryId && equipmentMasterId) {
      handleDeLinkTelemetryId()
    }
  }

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      equipment_master_id,
      equipment_type_id,
      equipment_model_id,
      serial_number,
      telemetry_device_id,
      equipment_category_id,
      equipment_make_id,
      creation_year,
      registration_no,
      model_no,
      equipment_variant_id,
      entity_code,
      equipment_address,
      verification_status_enum,
      equipment_description
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    if (isCtUser) {
      filterParams.Is_FetchAllEquipment = "true";
    }
    if (isMapTracker) {
      filterParams.Is_Tracker_Id_Mapped = "true";
    }
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
      Is_Invoked_On_Grid: true
    };
    dispatch(rentalActions.getAllEquipments(params));
  }, [
    equipment_master_id,
    equipment_type_id,
    equipment_make_id,
    creation_year,
    registration_no,
    model_no,
    equipment_variant_id,
    verification_status_enum,
    rowsPerPage,
    page,
    equipment_model_id,
    serial_number,
    telemetry_device_id,
    equipment_category_id,
    entity_code,
    equipment_address,
    equipment_description,
    rerender,
  ]);

  const back = utils.encodeBackUrl();

  const EQUIPMENT_RENTAL_TABLE_HEADERS = [
    {
      title: 'Id',
      dataIndex: 'equipment_master_id',
      key: 'equipment_master_id',
      width: '100px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => a.equipment_master_id - b.equipment_master_id,
      render: (text, row) => (
        isMapTracker ? (
          <div className="bfc-table-list-components text-center d-flex justify-content-center">
            <p style={{ color: 'black' }}>
              {row.equipment_master_id}
            </p>
          </div>
        ) : (
          <div className="bfc-table-list-components text-center d-flex justify-content-center">
            <Link to={getUrlWithEncryptedQueryParams(`/control-tower/equipment-rental/equipment-details/${row.equipment_master_id}?back=${back}`)}>
              {row.equipment_master_id}
            </Link>
          </div>
        )
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'equipment_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_master_id}
          name={'Id'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'equipment_master_id'),
      filteredValue: equipment_master_id !== undefined ? equipment_master_id : null,
    },
    {
      title: 'Category',
      dataIndex: 'equipment_category_id',
      key: 'equipment_category_id',
      width: '150px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.equipment_category_id - b.equipment_category_id,
      // sorter: (a, b) =>
      //   (a.equipment_category_id &&
      //     a.equipment_category_id.localeCompare(b.equipment_category_id && b.equipment_category_id))
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_category_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_category_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_category_id}
          name={'Category'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_category_id'),
      filteredValue: equipment_category_id !== undefined ? equipment_category_id : null,
    },
    {
      title: 'Type',
      dataIndex: 'equipment_type_code',
      key: 'equipment_type_code',
      width: '130px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) =>
        (a.equipment_type_code &&
          a.equipment_type_code.localeCompare(b.equipment_type_code && b.equipment_type_code)) ||
        (a.equipment_type_code && a.equipment_type_code.localeCompare(b.equipment_type_code && b.equipment_type_code)),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_type_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_type_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_type_id}
          name={'Type'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_type_id'),
      filteredValue: equipment_type_id !== undefined ? equipment_type_id : null,
    },
    {
      title: 'Maker',
      dataIndex: 'equipment_make_code',
      key: 'equipment_make_code',
      width: '130px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) =>
        (a.equipment_make_code &&
          a.equipment_make_code.localeCompare(b.equipment_make_code && b.equipment_make_code)) ||
        (a.equipment_make_code && a.equipment_make_code.localeCompare(b.equipment_make_code && b.equipment_make_code)),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_make_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_make_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_make_id}
          name={'Maker'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_make_id'),
      filteredValue: equipment_make_id !== undefined ? equipment_make_id : null,
    },
    {
      title: 'Variant',
      dataIndex: 'equipment_variant_code',
      key: 'equipment_variant_code',
      width: '120px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) =>
        (a.equipment_variant_code &&
          a.equipment_variant_code.localeCompare(b.equipment_variant_code && b.equipment_variant_code)) ||
        (a.equipment_variant_code &&
          a.equipment_variant_code.localeCompare(b.equipment_variant_code && b.equipment_variant_code)),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_variant_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_variant_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_variant_id}
          name={'Maker'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_variant_id'),
      filteredValue: equipment_variant_id !== undefined ? equipment_variant_id : null,
    },
    {
      title: 'Model',
      dataIndex: 'equipment_model_code',
      key: 'equipment_model_code',
      width: '150px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.equipment_model_code}
        </div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'equipment_model_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_model_id}
          name={'Model'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_model_id'),
      filteredValue: equipment_model_id !== undefined ? equipment_model_id : null,
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      key: 'serial_number',
      width: '140px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => (
        <>
          <Popover
            content={row?.serial_number}
            placement='topLeft'
          >
            <div className="bfc-table-list-components text-left">
              <p className='multiline-ellipsis text-theme-dark'>
                {row?.serial_number}
              </p>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'serial_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={serial_number}
          name={'Serial No'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'serial_number'),
      filteredValue: serial_number !== undefined ? serial_number : null,
    },
    {
      title: 'Tracker ID',
      dataIndex: 'telemetry_device_id',
      key: 'telemetry_device_id',
      width: '160px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-start">
          {row.telemetry_device_id}
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'telemetry_device_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={telemetry_device_id}
          name={'Telemetry Device ID'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'telemetry_device_id'),
      filteredValue: telemetry_device_id !== undefined ? telemetry_device_id : null,
    },
    {
      title: 'Description',
      dataIndex: 'equipment_description',
      key: 'equipment_description',
      width: '200px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => <div className="bfc-table-list-components">{row.equipment_description}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'equipment_description'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={equipment_description}
          name={'Equipment Description'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'equipment_description'),
      filteredValue: equipment_description !== undefined ? equipment_description : null,
    },
    {
      title: 'Creation Date',
      dataIndex: 'creation_year',
      key: 'creation_year',
      width: '150px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => a.creation_year && a.creation_year.localeCompare(b.creation_year && b.creation_year),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-center">
          {row.creation_year ? dayjs(row.creation_year).format('DD-MMM-YY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'creation_year'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={creation_year}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'creation_year'),
      filteredValue: creation_year !== undefined ? creation_year : null,
    },
    {
      title: 'Location',
      dataIndex: 'equipment_address',
      key: 'equipment_address',
      width: '150px',
      ellipsis: true,
      align: 'left',
      render: (text, row) => (
        <Popover
        content={<div style={{ maxWidth: '400px', wordWrap: 'break-word' }}>{row?.equipment_address}</div>}
        placement='topLeft'
      >
        <div className="bfc-table-list-components text-left">
          <p className='multiline-ellipsis text-theme-dark'>
          {row?.equipment_address}
          </p>
        </div>
      </Popover>
      ),
      filterDropdown: (props) => (
        // <AutoCompleteFilter
        //   dataIndex={'equipment_address'}
        //   codeBaisedFilter={true}
        //   data={props}
        //   onFilterChange={onFilterChange}
        //   handleReset={handleReset}
        //   value={equipment_address}
        //   name={'Address'}
        // />
        <TextSearchFilter
        dataIndex={'equipment_address'}
        data={props}
        onFilterChange={onFilterChange}
        handleReset={handleReset}
        value={equipment_address}
        name={'Address'}
      />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'equipment_address'),
      filteredValue: equipment_address !== undefined ? equipment_address : null,
    },
    {
      title: 'Equipment Owner',
      dataIndex: 'entity_code',
      key: 'entity_code',
      width: '170px',
      ellipsis: true,
      align: 'left',
      sorter: (a, b) => a.entity_code && a.entity_code.localeCompare(b.entity_code && b.entity_code),
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-start">
          {row.entity_code}
          <br />
          {row.mobile_number}
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'entity_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={entity_code}
          name={'Equipment Owner'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'entity_code'),
      filteredValue: entity_code !== undefined ? entity_code : null,
    },
    {
      title: 'Status',
      dataIndex: 'verification_status_enum_code',
      key: 'verification_status_enum_code',
      width: '100px',
      sorter: (a, b) =>
        a.verification_status_enum_code &&
        a.verification_status_enum_code.localeCompare(
          b.verification_status_enum_code && b.verification_status_enum_code,
        ),
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Pending' && (
            <Tag color={'lime'} key={text}>
              <div className="bfc-table-list-components text-center">{text?.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Rejected' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{text?.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Approved' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{text?.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'DELETED' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{text?.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">
                {text.toUpperCase()}
              </div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'verification_status_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={verification_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'verification_status_enum'),
      filteredValue: verification_status_enum !== undefined ? verification_status_enum : null,
    },
    (isMapTracker && canViewMapTracker) && {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      width: '150px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex justify-content-center">
          <Popconfirm
            onConfirm={() => handleDelinkId(row?.telemetry_device_id, row?.equipment_master_id)}
            placement="left"
            title="Are you sure to delink the Tracker ID?"
            description="Delink the ID"
            okText="Yes"
            cancelText="No"
          >
            <span
              style={{ color: '#2064A9', cursor: 'pointer' }}
            >
              Delink
            </span>
          </Popconfirm>
        </div>
      ),
    },
    // {
    //   title: 'Registration No',
    //   dataIndex: 'registration_no',
    //   key: 'registration_no',
    //   width: '150px',
    //   ellipsis: true,
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-center d-flex justify-content-center">
    //       {row?.registration_no?.toUpperCase()}
    //     </div>
    //   ),
    //   filterDropdown: (props) => (
    //     <TextSearchFilter
    //       dataIndex={'registration_no'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={registration_no}
    //       name={'Registration No'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getSearchIcon(filtered, 'registration_no'),
    //   filteredValue: registration_no !== undefined ? registration_no : null,
    // },
  ].filter(Boolean);

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };
  const GenerateEquipmentRentalReport = useSelector((state) => state.generateReportCommon);
  const tableData = equipmentData;
  const totalRecords = tableData?.[0]?.total_count || 0;
  const reportData = busy ? [] : Object.values(GenerateEquipmentRentalReport.entities || {});
  const getExportReport = async () => {
    const filterParams = {
      filters: {
        ...filters,
        // Is_FetchAllEquipment: "true"
      },
      page_size: 10000,
      offset: 0,
    };
    if (isCtUser) {
      filterParams.filters.Is_FetchAllEquipment = "true";
    }
    if (isMapTracker) {
      filterParams.filters.Is_Tracker_Id_Mapped = "true";
    }
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    const success = await dispatch(generateEquipmentRentalCSVReportActions.getEquipmentRentalReport(filterParams));
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="bfc-body">
      {/* {busy && (
        <div className="commodity-wrapper">
          <div className="panel__refresh">
            <LoadingIcon />
          </div>
        </div>
      )} */}
      <div>
        <OverviewHeader
          heading={isMapTracker ? `Map Trackers` : `Equipment Rental`}
          rightPanContent={
            <>
              {
                isMapTracker && canViewMapTracker ? (
                  <MUIButton
                  type='icon'
                  Icon={AddLinkIcon}
                  tooltipText='Link'
                        onClick={() => {
                      setShowMapTeleId(true)
                    }}
                  />
                  // <button
                  //   type="button"
                  //   className="btn btn-primary btn-sm"
                  //   onClick={() => {
                  //     setShowMapTeleId(true)
                  //     // setSingleRowData(row)
                  //   }}
                  // >
                  //   Link Tracker
                  // </button>
                ) : null
              }
            </>
          }
        />
        <div className="invoice-table bfc-body">
          <div>
            <div className="ant-table-body ant-table">
              <Table
                ref={tableRef}
                dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                columns={busy ? EQUIPMENT_RENTAL_TABLE_HEADERS.map((column) => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <Skeleton
                          active="true"
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                        />
                      );
                    },
                  };
                }) : EQUIPMENT_RENTAL_TABLE_HEADERS}
                pagination={false}
                onChange={handlePageChange}
                scroll={{ y: 510 }}
                className="ant-table"
                size="small"
              />
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                rowsPerPage={rowsPerPage}
                reportData={reportData}
                getExportReport={getExportReport}
                csvLink={csvLink}
                type={'Equipment Rental'}
                setScrollToFirstRow={setScrollToFirstRow}
              />
            </div>
          </div>
          {
            showMapTeleId && (
              <MapTelemetryIdPopupForm
                setShowMapTeleId={setShowMapTeleId}
                singleRowData={singleRowData}
                trackerId={singleRowData?.telemetry_device_id}
                equipmentMasterId={singleRowData?.equipment_master_id}
                rerender={rerender}
                setRerender={setRerender}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};
export default EquipmentRental;
