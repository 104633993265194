import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';

// linkUtils.js
export const getLinks = (
  isSupplyPartner,
  isControlTowerUser,
  isTransporter,
  typeOfIndividualEnum,
  context,
  loc,
  is_zoho_connected,
  activeTab,
  partnerId,
) => {
  console.log('OmkarPartnerId', partnerId);
  let links = [];
  const fiscal_year = utils.generateLastFiveYears()?.[0]?.value;
  if (isTransporter || typeOfIndividualEnum === 8) {
    if (context.ctx == '3') {
      links = [
        { to: '/network/invoices?page=1', title: 'Invoice' },
        { to: '/network/payments?page=1', title: 'Payment' },
      ];
    }
  }

  if (typeOfIndividualEnum === 7) {
    if (context.ctx == '3') {
      links = [{ to: '/network/payments?page=1', title: 'Payment' }];
    }
  }
  let activeTabPrev;
  if (loc.includes('/market')) {
    activeTabPrev = 'market';
    links = [
      { to: `/market/invoices?page=1&fiscal_year=${fiscal_year}`, title: 'Invoice' },
      { to: `/market/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Payment' },
      {
        to: `/market/advance/payments?page=1&fiscal_year=${fiscal_year}`,
        title: 'Advance',
      },
      { to: `/market/cashflow?page=1`, title: 'Cashflow' },
      ...(isSupplyPartner
        ? [{ to: `/market/cashflow-new?page=1&cashflow_status_enum_code=Not+Invoiced`, title: 'New Cashflow' }]
        : []),
      ...(isSupplyPartner ? [{ to: '/market/manual-invoice?page=1', title: 'Manual Invoice' }] : []),
      { to: `/market/ledger?page=1`, title: 'Ledger' },
    ];
  } else if (loc.includes('/network')) {
    activeTabPrev = 'network';
    links.push({ to: `/network/advance/payments?page=1`, title: 'Advance' });
    links.push({ to: `/network/ledger?page=1`, title: 'Ledger' });
  } else if (loc.includes('/controltower') || loc.includes('/control-tower')) {
    activeTabPrev = 'CT';
    links.push({ to: `/controltower/invoices?page=1&fiscal_year=${fiscal_year}`, title: 'Invoice' });
    links.push({ to: `/controltower/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Payment' });
    // if (isSupplyPartner) {
    if (!partnerId) {
      links.push({
        to: `/controltower/cashflow-new?page=1&cashflow_status_enum_code=Not+Invoiced`,
        title: 'New Cashflow',
      });
    }
    // }
    if (!partnerId) {
      links.push({ to: `/controltower/advance/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Advance' });
    }
    if (!partnerId) {
      links.push({ to: '/controltower/manual-invoice?page=1', title: 'Manual Invoice' });
    }
    if (!partnerId) {
      links.push({ to: `/control-tower/ledger?page=1`, title: 'Ledger' });
    }
  }
  if (loc.includes('/configuration/zoho-logs') || loc.includes('/configuration/zoho_payment-log')) {
    if (activeTab == 'market') {
      links = [
        { to: `/market/invoices?page=1&fiscal_year=${fiscal_year}`, title: 'Invoice' },
        { to: `/market/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Payment' },
        { to: `/market/advance/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Advance' },
        { to: `/market/cashflow?page=1`, title: 'Cashflow' },
        ...(isSupplyPartner
          ? [{ to: `/market/cashflow-new?page=1&cashflow_status_enum_code=Not+Invoiced`, title: 'New Cashflow' }]
          : []),
        { to: `/market/ledger?page=1`, title: 'Ledger' },
      ];
    } else if (activeTab == 'network') {
      links.push({ to: `/network/ledger?page=1`, title: 'Ledger' });
    } else if (activeTab == 'CT') {
      links.push({ to: `/controltower/invoices?page=1&fiscal_year=${fiscal_year}`, title: 'Invoice' });
      links.push({ to: `/controltower/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Payment' });
      links.push({ to: `/controltower/advance/payments?page=1&fiscal_year=${fiscal_year}`, title: 'Advance' });
      if (!partnerId) {
        links.push({
          to: `/controltower/cashflow-new?page=1&cashflow_status_enum_code=Not+Invoiced`,
          title: 'New Cashflow',
        });
      }
      if (!partnerId) {
        links.push({ to: '/controltower/manual-invoice?page=1', title: 'Manual Invoice' });
      }
      if (!partnerId) {
        links.push({ to: `/control-tower/ledger?page=1`, title: 'Ledger' });
      }
    }
  }

  if (is_zoho_connected) {
    links = [
      ...links,
      {
        title: 'Zoho Invoice Log',
        to: `/configuration/zoho-logs?page=1&activeTab=${activeTabPrev ? activeTabPrev : activeTab}`,
      },
      {
        title: 'Zoho Payment Log',
        to: `/configuration/zoho_payment-log?page=1&activeTab=${activeTabPrev ? activeTabPrev : activeTab}`,
      },
    ];
  }
  return links?.map((link) => ({ ...link, to: getUrlWithEncryptedQueryParams(link?.to) }));
};

export const getFuelManagementLinks = () => {
  const fiscal_year = utils.generateLastFiveYears()?.[0]?.value;
  let links = [
    { to: `/control-tower/fuel-price?page=1`, title: 'Fuel Price' },
    { to: `/control-tower/fuel-configuration?page=1`, title: 'Configuration' },
    { to: `/control-tower/fuel-management?page=1`, title: 'Fuel Management' },
    { to: `/control-tower/datum-configuration?page=1`, title: 'Datum Config' },
    { to: `/control-tower/fuel-dispensed?page=1&fiscal_year=${fiscal_year}`, title: 'Fuel Dispensed' },
  ];

  return links?.map((link) => ({ ...link, to: getUrlWithEncryptedQueryParams(link?.to) }));
};

export const getFuelManagementLinksNetwork = () => {
  const fiscal_year = utils.generateLastFiveYears()?.[0]?.value;
  let links = [{ to: `/network/fuel-dispensed?page=1&fiscal_year=${fiscal_year}`, title: 'Fuel Dispensed' }];

  return links?.map((link) => ({ ...link, to: getUrlWithEncryptedQueryParams(link?.to) }));
};
