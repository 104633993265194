import React, { PureComponent, useEffect, useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, Col } from 'reactstrap';
import validate from 'lib/validations/signup';

import { Formik, withFormik, Field, Form } from 'formik';
import FormikMaterialTextField from 'shared/components/form/FormikMaterialTextField';
import FormikCheckBox from 'shared/components/form/FormikCheckBox';
import * as handlers from './../handlers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import otplessSdk from "otpless-js-sdk";
import { actions as appConfigActions } from 'redux/appConfig';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/ErrorOutline';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../Login/components/custom.css';
import { getDecryptedUrl } from 'lib/utils';

const SignupForm = (props) => {
  const { isFarmerUser } = props;
  const dispatch = useDispatch();
  const loc = getDecryptedUrl(window.location.href);

  const [lengthCount, setLengthCount] = useState(0);
  const [showDisabled, setShowDisabled] = useState(false);
  const [showPhoneEmail, setShowPhoneEmail] = useState('email');
  const [showContryCode, setShowContryCode] = useState(91);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [countryPhone, setCountryPhone] = useState('');

  // const [countrySign, setCountrySign] = useState('');
  // const [countryCode, setCountryCode] = useState(0);
  // const [countryNumber, setCountryNumber] = useState('');

  // const [formikObject, setFormikObject] = useState({});

  // let initialValues;

  // if (countrySign != '+') {
  //   initialValues = {
  //     user_name: formikObject.user_name || '',
  //     agree: true,
  //     domain_url: window.location.origin,
  //     country_code: countryCode || 91,
  //   };
  //   console.log('check initialValues 60', formikObject, formikObject != '91', initialValues)
  // } else {
  //   // initialValues = formikObject;
  //   initialValues = {
  //     user_name: formikObject.user_name || '',
  //     // user_name: getUserName(),
  //     agree: true,
  //     domain_url: window.location.origin,
  //     country_code: countryCode || 91,
  //   };
  //   console.log('check initialValues 70', formikObject, formikObject != '91', initialValues)
  // }


  // useEffect(() => {
  //   if (lengthCount > 0) {
  //     const pattern =  /^(\+\d{1,})?(\d{1,})$/;  ///^\+[0-9]+$/; ///^-?\d+$/;
  //     const checkPattern = pattern.test(lengthCount);
  //     let getPlusSign = lengthCount?.charAt(0);
  //     let getCountryCode = lengthCount?.substr(0, 3);
  //     console.log('check CountryCodeJSON', lengthCount, checkPattern, getPlusSign, getCountryCode, (getPlusSign == '+' && getCountryCode == '+91'));

  //     // if (/^(\+\d{1,})?(\d{1,})$/) {
  //     // }
  //     if (getPlusSign == '+' && getCountryCode == '+91') {
  //       if (getPlusSign) {
  //         setCountrySign(getPlusSign);
  //       }
  //       if (getCountryCode == '+91') {
  //         setCountryCode(getCountryCode);
  //       }
  //       let getphoneNumber = lengthCount?.substr(3, 15);
  //       if (getphoneNumber) {
  //         setCountryNumber(getphoneNumber);
  //       }
  //       console.log('check lengthCount', getCountryCode, getphoneNumber);
  //     } if (getPlusSign == '+' && getCountryCode != '+91') {
  //       if (getPlusSign) {
  //         setCountrySign(getPlusSign);
  //       }
  //       if (getCountryCode == '+91') {
  //         setCountryCode(getCountryCode);
  //       }
  //       let getphoneNumber = lengthCount?.substr(3, 15);
  //       if (getphoneNumber) {
  //         setCountryNumber(getphoneNumber);
  //       }
  //       console.log('check lengthCount', getCountryCode, getphoneNumber);
  //     } else {
  //       let getphoneNumber = lengthCount?.substr(0, 15);
  //       setCountryNumber(getphoneNumber);
  //       console.log('check lengthCount', getphoneNumber, lengthCount);
  //     }
  //   }
    // const pattern = /^-?\d+$/;
    // if (lengthCount.length > 8) {
    //   if (pattern.test(lengthCount) && lengthCount != 0) {
    //     setShowPhone(true);
    //     setShowEmail(false);
    //   } else {
    //     setShowEmail(true);
    //     setShowPhone(false);
    //   }
    // } else {
    //   setShowEmail(true);
    //   setShowPhone(false);
    // }
  // }, [lengthCount])
  // useEffect(() => {
  //   const pattern = /^-?\d+$/;
  //   if (lengthCount.length > 8) {
  //     if (pattern.test(lengthCount) && lengthCount != 0) {
  //       setShowPhone(true);
  //       setShowEmail(false);
  //     } else {
  //       setShowEmail(true);
  //       setShowPhone(false);
  //     }
  //   } else {
  //     setShowEmail(true);
  //     setShowPhone(false);
  //   }
  // }, [lengthCount])

  let sdkInStance;
  let getIntent;

  // const validatePhoneNumber = (value, country) => {
  //   const onlyNumbers = value.replace(/\D/g, '');
  //   return onlyNumbers.length === 10;
  // };

  useEffect(() => {
    (async () => {
      const clientId = await dispatch(appConfigActions.getAppConfig({ config_parameter: 'OTPless_Client_Id' }))
      // const clientSecret = await dispatch(appConfigActions.getAppConfig({ config_parameter: 'OTPless_Client_Secret' }))
      sdkInStance = otplessSdk({
        appId: clientId,
        enableErrorLogging: true,
      });
      let UrlToken = sdkInStance.getToken();
      UrlToken && setToken(UrlToken);
    })()
  });

  const initiateLogin = () => {
    let redirectionUrl = 'https://my.biofuelcircle.com/sso/WA/callback';

    if (loc.includes('/smartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://smartbuyer.biofuelcircle.com/sso/WA/callback';
    }

    if (loc.includes('assist.biofuelcircle.com')) {
      redirectionUrl = 'https://assist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prep.biofuelcircle.com')) {
      redirectionUrl = 'https://prep.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prepsmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://prepsmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prepassist.biofuelcircle.com')) {
      redirectionUrl = 'https://prepassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demo.biofuelcircle.com')) {
      redirectionUrl = 'https://demo.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demosmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://demosmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demoassist.biofuelcircle.com')) {
      redirectionUrl = 'https://demoassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('test.biofuelcircle.com')) {
      redirectionUrl = 'https://test.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('testsmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://testsmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('testassist.biofuelcircle.com')) {
      redirectionUrl = 'https://testassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('localhost:3000')) {
      redirectionUrl = 'http://localhost:3000/sso/WA/callback';
    }

    getIntent = sdkInStance.createGetIntentOnClick({
      redirectionURL: redirectionUrl,
    });
    return getIntent();
  };


  const whatsAppButtonStyle = {
    backgroundColor: '#25D366',
    padding: '10px 40px',
    border: 'none',
    borderRadius: '6px',
    fontSize: ' var(--fs-base__two)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }

  const separatorStyle = {
    position: 'absolute',
    top: '-12px',
    background: '#fff',
    left: '44%',
    height: '25px',
    width: '36px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#707070',
  }

  return (
    <Col md={12} lg={12}>
      <Card className="pb-15">
        <CardBody>
          <Formik
            // initialValues={initialValues}
            initialValues={{
              user_name: '',
              agree: true,
              country_code: 0,
            }}
            validate={validate}
            onSubmit={handlers.handleRequestOTP}>
            {(formikBag) => {
              return (
                <Form className="material-form">
                  <div>
                    <div className="login-signup__page">
                      <br />
                      <div className="d-flex align-items-baseline signup-card mb-2">
                        {showPhoneEmail === 'email' ? (
                        <Field
                          style={{ width: formikBag.isSubmitting ? 'calc(100% - 162px)' : 'calc(100% - 138px)' }}
                          className="mr-3"
                          name="user_name"
                          variant={'outlined'}
                          size="small"
                          component={FormikMaterialTextField}
                          // placeholder="Enter Mobile Number/ Email Address"
                          // label={isFarmerUser ? 'Enter Mobile Number' : "Enter Mobile Number/ Email Address"}
                          placeholder={isFarmerUser ? 'Enter Mobile Number' : "Enter Email"}
                          isFarmerUser={isFarmerUser}
                          setShowDisabled={props.setShowDisabled}
                          setShowEmail={setShowEmail}
                          isSignup={true}
                        />
                        ) : (
                          <>
                          <PhoneInput
                            country={'in'}
                            // value={lengthCount === 0 ? countryPhone : '+91' + lengthCount}
                            preferredCountries={['in']}
                            enableSearch={true}
                            // placeholder="Enter Mobile Number"
                            onChange={(event, name) => {
                                setCountryPhone(event);
                                const originalString = event;
                                const dialCodeLength = name.dialCode;
                                const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                                if (originalString > 0) {
                                  formikBag.setFieldValue('user_name', stringWithoutFirstThree || '');
                                  formikBag.setFieldValue('country_code', parseInt(name.dialCode) || 0);
                                  setLengthCount(stringWithoutFirstThree);
                                  setShowContryCode(parseInt(name.dialCode))
                                } else {
                                  formikBag.setFieldValue('user_name', '');
                                  formikBag.setFieldValue('country_code', 0);
                                  setShowContryCode(91)
                                  setLengthCount(0);
                                }
                                if (name.dialCode != "91") {
                                  props.setShowDisabled(true);
                                } else {
                                  props.setShowDisabled(false);
                                }
                              }
                            }
                            prefix={''}
                            autoFormat={false}
                            // isValid={validatePhoneNumber}
                            // countryCodeEditable={false}
                            inputProps={{
                              name: 'mobile_number',
                              required: true,
                              placeholder: "Enter Mobile Number",
                              autoFocus: true,
                              className: ((lengthCount.length > 10 || lengthCount.length < 10) && formikBag.values.user_name != '' && showPhoneEmail === 'phone no') ? 'custom-phone-input error-msg__bordered' : 'custom-phone-input',
                              'data-country-code': `+${''}`,
                            }}
                          />
                          <span className="country-code position-absolute">{'+' + showContryCode}</span>
                          </>
                        )}
                        <div className="bfc-center">
                          <button
                            disabled={showEmail || (lengthCount.length > 10 && !showEmail) || formikBag.isSubmitting || props.showDisabled}
                            type="submit"
                            color="primary"
                            size="sm"
                            style={{...whatsAppButtonStyle, backgroundColor: "var(--unnamed-color-1b62ab)", opacity: props.showDisabled && '0.6', cursor: props.showDisabled && 'not-allowed'}}>
                            {formikBag.isSubmitting && (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            )}
                            Verify
                          </button>
                        </div>
                      </div>

                      {((lengthCount.length > 10 || lengthCount.length < 10) && formikBag.values.user_name != '' && showPhoneEmail === 'phone no') ? (
                        <span className="error-msg">Phone number must be 10 digits.</span>
                      ) : (showEmail && formikBag.values.user_name != '' && showPhoneEmail === 'email') && (
                        <span className="error-msg">Email address cannot start with a number.</span>
                      )}

                      <p>{showPhoneEmail === 'phone no' ? <span>Sign-Up using <span className="text-blue cursor-pointer" onClick={() => {setShowPhoneEmail('email'); formikBag.setFieldValue('user_name', '')}}>Email</span></span> : <span>Sign-Up using <span className="text-blue cursor-pointer" onClick={() => {setShowPhoneEmail('phone no'); formikBag.setFieldValue('user_name', '')}}>Phone number</span></span> }</p>
                      <div className='position-relative'>
                          <hr />
                          <span style={separatorStyle}>OR</span>
                        </div>
                        <div>
                          <button
                            size="sm"
                            className='mr-3 mb-3'
                            style={whatsAppButtonStyle}
                            onClick={() => initiateLogin()}
                          >
                            <WhatsAppIcon size={24} className="mr-2" />
                            Sign-Up With Whatsapp
                          </button>
                        </div>
                    </div>

                    {/* {isFarmerUser ? <p className="bfc-otpVerification">
                      A six digit OTP will be sent via SMS<br />
                      <br />
                    </p> : <p className="bfc-otpVerification">
                      A six digit OTP will be sent via SMS/ <br />
                      Email for verification
                      <br />
                      <br />
                    </p>} */}
                  </div>

                  {
                    <div className="form__form-group">
                      <div className="w-100 ml-0">
                        <Field
                          name="agree"
                          component={FormikCheckBox}
                          label={
                            <div className="bfc-login-page">
                              <span>
                                Agree with{' '}
                                <Link className="bfc-underline" to="/terms-and-conditions.html" target="_blank">
                                  Terms and Conditions
                                </Link>
                              </span>
                              {/* <br /> */}
                              <span> & </span>
                              <span>
                                <Link className="bfc-underline" to="/privacy-notice.html" target="_blank">
                                  Privacy Notice
                                </Link>
                              </span>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  }
                  {/* <div className="bfc-center">
                    <Button disabled={formikBag.isSubmitting} type="submit" color="primary" size="sm">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      Request OTP
                    </Button>
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SignupForm;
